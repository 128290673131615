import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface AuthRouteProps {
  children: ReactNode;
}

const AuthRoute: React.FC<AuthRouteProps> = ({ children }) => {
  const tokenData = localStorage.getItem('token');

  if (!tokenData) {
    // If no token is found, redirect to the sign-in page
    return <Navigate to="/signin" />;
  }

  // If token exists, allow access to the protected route
  return <>{children}</>;
};

export default AuthRoute;
