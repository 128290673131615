import React, { useEffect, useState } from 'react';
import {
  Container,
  Box,
  Typography,
  BottomNavigationAction,
  BottomNavigation,
  styled,
  Button,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AddHomeIcon from '@mui/icons-material/AddHome';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link } from 'react-router-dom';
import axios, { AxiosError } from 'axios';

// Styled components
const ReferContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  background-color: #121212;
  padding-bottom: 56px;
`;

const Card = styled(Box)`
  padding: 20px;
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  margin: 20px 0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledBottomNavigation = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #1e1e1e;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.5);
  padding: 10px 0;

  .MuiBottomNavigationAction-root {
    color: rgba(255, 255, 255, 0.7);
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: translateY(-5px);
    }

    &.Mui-selected {
      color: #00D4AA;
      transform: translateY(-5px);
    }
  }

  .MuiSvgIcon-root {
    font-size: 2rem;
  }

  .Mui-selected .MuiSvgIcon-root {
    font-size: 2.2rem;
  }

  .MuiBottomNavigationAction-label {
    font-size: 0.9rem;
    font-weight: 500;
  }
`;

interface ErrorResponse {
  message?: string;
}

const ReferPage: React.FC = () => {
  const [username, setUsername] = useState(() => {
    const storedUsername = localStorage.getItem('username');
    return storedUsername || 'your_username'; // Default if not found
  });
  const [referralLink] = useState(`hhttps://bepjet.com/signup?ref=${username}`); // Construct referral link
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeTab, setActiveTab] = useState(2);
  const [referralData, setReferralData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // State for referral counters
  const [firstGenCount, setFirstGenCount] = useState(0);
  const [secondGenCount, setSecondGenCount] = useState(0);
  const [thirdGenCount, setThirdGenCount] = useState(0);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink);
    alert('Referral link copied to clipboard!');
  };

  const referralCount = async () => {
    setLoading(true);
    setError(null); // Reset any previous errors
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await axios.get('https://client.ecosystemdev.com/bep/referrals', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Set the referral counts based on the response
      setFirstGenCount(response.data.firstGenerationCount);
      setSecondGenCount(response.data.secondGenerationCount);
      setThirdGenCount(response.data.thirdGenerationCount);
      setReferralData(response.data); // Save the entire referral data for further use
    } catch (err) {
      const axiosError = err as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data.message || 'An error occurred';
      setError(message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    referralCount();
  }, []);

  return (
    <ReferContainer>
      <Typography variant="h4" color="#fff" sx={{ margin: '20px 0', textAlign: 'center' }}>
        Referral Program
      </Typography>

      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <>
          <Card>
            <Typography variant="h5" color="#fff">ReferId: {username}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px', flexDirection: isSmallScreen ? 'column' : 'row' }}>
              <Typography variant="body1" color="#00D4AA" sx={{ flexGrow: 1, textAlign: isSmallScreen ? 'center' : 'left' }}>{referralLink}</Typography>
              <Button onClick={handleCopyLink} sx={{ marginTop: isSmallScreen ? '10px' : '0', backgroundColor: '#00D4AA', color: '#fff' }}>
                Copy
              </Button>
            </Box>
          </Card>

         

          {/* Individual Cards for each Generation Referral Count */}
          <Card>
            <Typography variant="h3" color="#00D4AA">{firstGenCount}</Typography>
            <Typography variant="body1" color="#fff">1st Gen Referrals</Typography>
          </Card>

          <Card>
            <Typography variant="h3" color="#00D4AA">{secondGenCount}</Typography>
            <Typography variant="body1" color="#fff">2nd Gen Referrals</Typography>
          </Card>

          <Card>
            <Typography variant="h3" color="#00D4AA">{thirdGenCount}</Typography>
            <Typography variant="body1" color="#fff">3rd Gen Referrals</Typography>
          </Card>

       

          {error && <Typography color="error">{error}</Typography>}
        </>
      )}

      <StyledBottomNavigation
        value={activeTab}
        onChange={(event, newValue) => setActiveTab(newValue)}
      >
        <BottomNavigationAction label="Home" icon={<AddHomeIcon />} component={Link} to="/dashboard" />
        <BottomNavigationAction label="Wallet" icon={<AccountBalanceWalletIcon />} component={Link} to="/wallet" />
        <BottomNavigationAction label="Referral" icon={<Diversity3Icon />} />
        <BottomNavigationAction label="Account" icon={<AccountCircleIcon />} component={Link} to="/account" />
      </StyledBottomNavigation>
    </ReferContainer>
  );
};

export default ReferPage;
