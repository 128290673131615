import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Container, Box, Typography, TextField, Button, styled, CircularProgress } from '@mui/material';
import axios from 'axios';

// Styled components
const SignupContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #00A4CCFF, #00D4AA);
`;

const SignupBox = styled(Box)`
  background-color: #212121;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.6);
  max-width: 400px;
  width: 100%;
`;

const SignupTitle = styled(Typography)`
  text-align: center;
  margin-bottom: 30px;
  font-size: 26px;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(90deg, #00A4CCFF, #00D4AA);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StyledTextField = styled(TextField)`
  & label.Mui-focused {
    color: #fff; 
  }
  & label {
    color: #fff; 
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #00A4CCFF;
    }
    &:hover fieldset {
      border-color: #00D4AA;
    }
    &.Mui-focused fieldset {
      border-color: #00c684;
    }
    & input {
      color: #fff; 
    }
  }
`;

const StyledButton = styled(Button)`
  margin-top: 30px;
  padding: 10px 0;
  background: linear-gradient(90deg, #00A4CCFF, #00D4AA);
  color: #212121;
  font-weight: bold;
`;

const LinkText = styled(Typography)`
  text-align: center;
  margin-top: 20px;
  color: #fff;
  a {
    color: #00D4AA;
    text-decoration: none;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Signup: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    username: '',
    email: '',
    password: '',
    password_confirmation: '',
    phone: '',
    country: '',
    refer: '',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    // Check if passwords match
    if (formData.password !== formData.password_confirmation) {
      setError("The password confirmation does not match.");
      setLoading(false);
      return;
    }

    try {
      // Send the POST request to the Laravel API route
      const response = await axios.post('https://client.ecosystemdev.com/register-post', formData, {
        headers: { 'Content-Type': 'application/json' },
      });
      setSuccess(response.data.message || 'Signup successful!');
      navigate('/Signin');
    } catch (err: any) {
      console.error(err.response.data);
      setError(err.response?.data.message || 'Signup failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <SignupContainer>
      <SignupBox>
        <SignupTitle variant="h4">Create an Account</SignupTitle>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <StyledTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Enter Your Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <StyledTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Enter Your Username"
            name="username"
            value={formData.username}
            onChange={handleChange}
          />
          <StyledTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Enter Your Email Address"
            name="email"
            autoComplete="email"
            value={formData.email}
            onChange={handleChange}
          />
          <StyledTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
          <StyledTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="confirm-password"
            label="Confirm Password"
            type="password"
            name="password_confirmation"
            value={formData.password_confirmation}
            onChange={handleChange}
          />
          <StyledTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Phone Number"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
          <StyledTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="country"
            label="Country"
            name="country"
            value={formData.country}
            onChange={handleChange}
          />
          <StyledTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="refer"
            label="Enter Your Refer Code"
            name="refer"
            value={formData.refer}
            onChange={handleChange}
          />
          <StyledButton type="submit" fullWidth variant="contained" disabled={loading}>
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign Up'}
          </StyledButton>
        </form>
        {error && <Typography color="error">{error}</Typography>}
        {success && <Typography color="primary">{success}</Typography>}
        <LinkText>
          Already have an account? <RouterLink to="/Signin">Login here</RouterLink>
        </LinkText>
      </SignupBox>
    </SignupContainer>
  );
};

export default Signup;
