import React, { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import {
  Container,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';

const HistoryContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  background-color: #121212;
  padding-bottom: 56px;
`;

const HistoryTable = styled(TableContainer)`
  width: 90%;
  margin-top: 20px;
  background-color: #1e1e1e;
`;

const DepositHistoryPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [history, setHistory] = useState<any[]>([]);

  const fetchDepositHistory = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
console.log(token)
      const response = await axios.get('https://client.ecosystemdev.com/deposits', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setHistory(response.data.deposits);
    } catch (err) {
      const axiosError = err as AxiosError;
      const message = axiosError.response?.data as { message?: string };
      setError(message?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDepositHistory();
  }, []);

  return (
    <HistoryContainer>
      <Typography variant="h4" color="#fff" sx={{ margin: '20px 0' }}>Deposit History</Typography>
      {loading ? (
        <CircularProgress color="inherit" />
      ) : error ? (
        <Typography variant="body1" color="red">{error}</Typography>
      ) : history.length === 0 ? (
        <Typography variant="body1" color="#fff">No deposit history available.</Typography>
      ) : (
        <Paper sx={{ width: '90%', marginTop: '20px', backgroundColor: '#1e1e1e' }}>
          <HistoryTable>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: '#fff' }} align="right">Amount</TableCell>
                  <TableCell sx={{ color: '#fff' }} align="right">Status</TableCell>
                  <TableCell sx={{ color: '#fff' }} align="right">Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history.map((deposit) => (
                  <TableRow key={deposit.id}>
                    <TableCell sx={{ color: '#00D4AA' }} align="right">{deposit.forwarded_coin}</TableCell>
                    <TableCell sx={{ color: '#fff' }} align="right">{deposit.pending === 0 ? 'Completed' : 'Pending'}</TableCell>
                    <TableCell sx={{ color: '#fff' }} align="right">{new Date(deposit.created_at).toLocaleDateString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </HistoryTable>
        </Paper>
      )}
    </HistoryContainer>
  );
};

export default DepositHistoryPage;
