import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Signup from './component/auth/Signup';
import Login from './component/auth/signin';
import Dashboard from './component/dashboard';
import AuthRoute from './component/AuthRoute'; // Adjust the import path if necessary
import DepositPage from './component/deposit'; // Adjust the import path if necessary
import WithdrawPage from './component/withdraw'; // Adjust the import path if necessary
import ReferralPage from './component/referral'; // Adjust the import path if necessary
import AccountPage from './component/account'; // Adjust the import path if necessary
import Wallet from './component/wallet'; // Adjust the import path if necessary
import DepositHistoryPage from './component/DepositHistoryPage'; // Import the new page

function App() {
  // Check if the token exists in localStorage
  const tokenData = localStorage.getItem('token');

  return (
    <Router>
      <Routes>
        {/* If the token exists, redirect to the dashboard */}
        <Route path="/signup" element={tokenData ? <Navigate to="/dashboard" /> : <Signup />} />
        <Route path="/signin" element={tokenData ? <Navigate to="/dashboard" /> : <Login />} />
        <Route path="/deposit-history" element={<DepositHistoryPage />} />
        <Route path="/" element={tokenData ? <Navigate to="/dashboard" /> : <Login />} />
        {/* Protected routes */}
        <Route 
          path="/deposit" 
          element={
            <AuthRoute>
              <DepositPage />
            </AuthRoute>
          } 
        />
        <Route 
          path="/withdraw" 
          element={
            <AuthRoute>
              <WithdrawPage />
            </AuthRoute>
          } 
        />
        <Route 
          path="/refer" 
          element={
            <AuthRoute>
              <ReferralPage />
            </AuthRoute>
          } 
        />
        <Route 
          path="/account" 
          element={
            <AuthRoute>
              <AccountPage />
            </AuthRoute>
          } 
        />
        <Route 
          path="/wallet" 
          element={
            <AuthRoute>
              <Wallet />
            </AuthRoute>
          } 
        />

        {/* Protected Dashboard Route */}
        <Route 
          path="/dashboard" 
          element={
            <AuthRoute>
              <Dashboard />
            </AuthRoute>
          } 
        />
      </Routes>
    </Router>
  );
}

export default App;
