import React, { useEffect, useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  BottomNavigation,
  BottomNavigationAction,
  styled,
  TextField,
  CircularProgress,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import AddHomeIcon from '@mui/icons-material/AddHome';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link } from 'react-router-dom';
import axios, { AxiosError } from 'axios';

// Styled components
const WithdrawContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  background-color: #121212;
  padding-bottom: 56px;
`;

const Card = styled(Box)`
  padding: 20px;
  border-radius: 15px;
  width: 90%;
  margin: 20px 0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledButton = styled(Button)`
background-color: #00d4aa; // Your site's button color
color: #fff; // Button text color
margin-top: 20px;

&:hover {
  background-color: #00a4cc; // Darker shade for hover
}
`;
const WithdrawButton = styled(Button)`
  padding: 20px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
  &:hover {
    background-color: #2a2a2a;
  }
`;
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& label': {
    color: '#fff', // Default label color
  },
  '& input': {
    color: '#fff', // Input text color
  },
  '& .MuiInput-underline:before': {
    borderBottom: '1px solid #fff', // Default underline color
  },
  '& .MuiInput-underline:after': {
    borderBottom: '2px solid #00D4AA', // Underline color on focus
  },
  '&:focus-within label': {
    color: '#00D4AA', // Change label color on focus
  },
  '&:focus-within input': {
    color: '#00D4AA', // Change input text color on focus
  },
  margin: '10px 0 15px', // Margin for spacing
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#00D4AA', // Change border color on focus
    },
    },
 
  
}));
const StyledBottomNavigation = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #1e1e1e;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.5);
  
  .MuiBottomNavigationAction-root {
    color: rgba(255, 255, 255, 0.7);
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: translateY(-5px);
    }

    &.Mui-selected {
      color: #00D4AA;
      transform: translateY(-5px);
    }
  }

  .MuiSvgIcon-root {
    font-size: 2rem;
  }

  .Mui-selected .MuiSvgIcon-root {
    font-size: 2.2rem;
  }

  .MuiBottomNavigationAction-label {
    font-size: 0.9rem;
    font-weight: 500;
  }
`;

const WithdrawPage: React.FC = () => {
  const [amount, setAmount] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [withdraws, setWithdraws] = useState<any[]>([]);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };

  const handleWithdraw = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccessMessage('');

    const data = {
      amount,
      wallet_address: walletAddress,
      withdraw_type: 'USDT',
    };

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('No token found');
        return;
      }
      
      const response = await axios.post('https://client.ecosystemdev.com/bep/withdraw/create', data, {
        withCredentials: true,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      console.log('Withdrawal Successful:', response.data);
      setSuccessMessage('Withdrawal successful!');
      fetchWithdrawHistory(); // Refresh withdraw history
    } catch (err: any) {
      if (err.response) {
        setError(err.response.data?.message || 'An error occurred during withdrawal.');
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
      console.error('Error during withdrawal:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchWithdrawHistory = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await axios.get('https://client.ecosystemdev.com/bep/withdraw', {
        withCredentials: true,
         headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setWithdraws(response.data.withdraws || []); // Set withdraw history correctly
    } catch (err) {
      const axiosError = err as AxiosError;
      const message = axiosError.response?.data as { message?: string };
      setError(message.message || 'Failed to load withdraw history');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWithdrawHistory();
  }, []);

  return (
    <WithdrawContainer>
      <Typography variant="h4" color="#fff" sx={{ margin: '20px 0' }}>Withdraw</Typography>

      <Card>
        {/* Input Fields */}
        <StyledTextField
          label="Amount"
          variant="outlined"
          fullWidth
         
          value={amount}
          onChange={handleAmountChange}
        />
        <StyledTextField
          label="Wallet Address"
          variant="outlined"
          fullWidth
          
          value={walletAddress}
          onChange={(e) => setWalletAddress(e.target.value)}
        />

        <StyledButton onClick={handleWithdraw} disabled={loading}>
          <Typography variant="body1" color="#fff" sx={{ marginTop: '8px' }}>
            {loading ? 'Processing...' : 'Proceed to Withdraw'}
          </Typography>
        </StyledButton>

        {error && <Typography color="error">{error}</Typography>}
        {successMessage && <Typography color="success.main">{successMessage}</Typography>}
      </Card>

      <Card>
        {loading ? (
          <CircularProgress color="inherit" />
        ) : (
          <>
            <Typography variant="h5" color="#fff" sx={{ marginBottom: '10px', textAlign: 'center' }}>
              Withdraw History
            </Typography>
            {withdraws.length === 0 ? (
              <Typography variant="body1" color="#fff">No withdraw history available.</Typography>
            ) : (
              <TableContainer
              component={Paper}
              sx={{ width: '100%', marginTop: '10px', backgroundColor: '#1e1e1e' }}
          >
              <Table>
                  <TableHead>
                      <TableRow>
                          <TableCell sx={{ color: '#fff' }}>Date</TableCell>
                          <TableCell sx={{ color: '#fff' }}>Status</TableCell>
                          <TableCell sx={{ color: '#fff' }}>Amount</TableCell>
                          <TableCell sx={{ color: '#fff' }}>Sender Address</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {withdraws.map((withdraw) => (
                          <TableRow key={withdraw.id}>
                              <TableCell sx={{ color: '#fff' }}>
                                  {new Date(withdraw.created_at).toLocaleDateString()}
                              </TableCell>
                              <TableCell sx={{ color: '#fff' }}>
                                  {withdraw.status} {/* Display the status directly */}
                              </TableCell>
                              <TableCell sx={{ color: '#fff' }}>
                                  {parseFloat(withdraw.amount).toFixed(2)} {/* Format amount */}
                              </TableCell>
                              <TableCell sx={{ color: '#fff' }}>{withdraw.wallet_address}</TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </Table>
          </TableContainer>
            )}
          </>
        )}
      </Card>

      <StyledBottomNavigation>
        <BottomNavigationAction label="Home" icon={<AddHomeIcon />} component={Link} to="/dashboard" />
        <BottomNavigationAction label="Wallet" icon={<AccountBalanceWalletIcon />} component={Link} to="/wallet" />
        <BottomNavigationAction label="Referral" icon={<Diversity3Icon />} component={Link} to="/refer" />
        <BottomNavigationAction label="Account" icon={<AccountCircleIcon />} component={Link} to="/account" />
      </StyledBottomNavigation>
    </WithdrawContainer>
  );
};

export default WithdrawPage;
