import React from 'react';
import {
  Container,
  Typography,
  styled,
  Box,
  BottomNavigation,
  BottomNavigationAction,
} from '@mui/material';
import { AddHome, AccountBalanceWallet, Diversity3, AccountCircle } from '@mui/icons-material';
import { Link } from 'react-router-dom';

// Styled components
const AccountContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  background-color: #121212;
  padding-bottom: 56px;
`;

const StyledBottomNavigation = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #1e1e1e;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.5);
  padding: 10px 0;
  z-index: 1000;

  .MuiBottomNavigationAction-root {
    color: rgba(255, 255, 255, 0.7);
    transition: all 0.3s ease-in-out;

    &.Mui-selected {
      color: #00D4AA;
      transform: translateY(-5px);
    }
  }

  .MuiSvgIcon-root {
    font-size: 2rem;
  }

  .MuiBottomNavigationAction-label {
    font-size: 0.9rem;
    font-weight: 500;
  }
`;

const AccountPage: React.FC = () => {
  return (
    <AccountContainer>
      <Typography variant="h4" color="#fff" sx={{ margin: '20px 0' }}>
        Become a Leader
      </Typography>
      <Typography variant="h6" color="#00D4AA" sx={{ margin: '10px 0' }}>
        Coming Soon
      </Typography>

      <StyledBottomNavigation>
        <BottomNavigationAction label="Home" icon={<AddHome />} component={Link} to="/dashboard" />
        <BottomNavigationAction label="Wallet" icon={<AccountBalanceWallet />} component={Link} to="/wallet" />
        <BottomNavigationAction label="Referral" icon={<Diversity3 />} component={Link} to="/refer" />
        <BottomNavigationAction label="Account" icon={<AccountCircle />} />
      </StyledBottomNavigation>
    </AccountContainer>
  );
};

export default AccountPage;
