import React, { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { Container, Box, Typography, BottomNavigation, BottomNavigationAction, styled, Button, CircularProgress } from '@mui/material';
import AddHomeIcon from '@mui/icons-material/AddHome';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DepositImage from './image/deposit.png';
import WithdrawImage from './image/withdraw.png';
import { Link } from 'react-router-dom';

// Styled components
const DashboardContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  background-color: #121212;
  padding-bottom: 56px;
`;

const Card = styled(Box)`
  padding: 20px;
  border-radius: 15px;
  width: 90%;
  margin: 20px 0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonGroup = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ActionButton = styled(Button)`
  flex: 1;
  padding: 20px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
  &:hover {
    background-color: #2a2a2a;
  }
`;

const InfoCard = styled(Box)`
  flex: 1;
  margin: 10px;
  width: calc(50% - 20px);
  box-sizing: border-box;
  padding: 20px;
  border-radius: 10px;
  background-color: #1e1e1e;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
`;

const StyledBottomNavigation = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #1e1e1e;  
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.5);
  padding: 10px 0;

  .MuiBottomNavigationAction-root {
    color: rgba(255, 255, 255, 0.7);
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: translateY(-5px);
    }

    &.Mui-selected {
      color: #00D4AA;
      transform: translateY(-5px);
    }
  }

  .MuiSvgIcon-root {
    font-size: 2rem;
  }

  .Mui-selected .MuiSvgIcon-root {
    font-size: 2.2rem;
  }

  .MuiBottomNavigationAction-label {
    font-size: 0.9rem;
    font-weight: 500;
  }
`;

interface DashboardData {
  total_available_balance: number;
  total_deposit_balance: number;
  total_withdraw_balance: number;
  total_referred_gift_balance: number;
  todays_earnings: number;
}

const Dashboard: React.FC = () => {
  const [dashboardData, setDashboardData] = useState<DashboardData>({
    total_available_balance: 0,
    total_deposit_balance: 0,
    total_withdraw_balance: 0,
    total_referred_gift_balance: 0,
    todays_earnings: 0,
  });
  const [error, setError] = useState<string>('');
  const [activeTab, setActiveTab] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);


  const fetchDashboardData = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
  
      const response = await axios.get('https://client.ecosystemdev.com/bep/dashboard', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
     
      
      // Assuming the real data is nested inside response.data.data
      setDashboardData(response.data.data);
      setLoading(false);
    } catch (error: any) {
      console.error('Error fetching dashboard data:', error.response?.status, error.response?.data);
      setError('Failed to load dashboard data.');
      setLoading(false);
    }
  };
  




useEffect(() => {
    fetchDashboardData();
}, []);












return (
  <DashboardContainer>
    <Typography variant="h4" color="#fff" sx={{ margin: '20px 0' }}>Dashboard</Typography>

    {error ? (
      <Typography variant="body1" color="red">{error}</Typography>
    ) : (
      <>
        <ButtonGroup>
          <ActionButton>
            <Link to="/deposit" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img src={DepositImage} alt="Deposit" style={{ width: '60px', height: '60px' }} />
              <Typography variant="body1" color="#fff" sx={{ marginTop: '8px' }}>Deposit</Typography>
            </Link>
          </ActionButton>

          <ActionButton>
            <Link to="/withdraw" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img src={WithdrawImage} alt="Withdraw" style={{ width: '60px', height: '60px' }} />
              <Typography variant="body1" color="#fff" sx={{ marginTop: '8px' }}>Withdraw</Typography>
            </Link>
          </ActionButton>
        </ButtonGroup>

        <Card>
          <Typography variant="h5" color="#fff">Total Balance (USD)</Typography>
          {loading ? ( // Loading state for Total Balance
            <Typography variant="h6" color="#00D4AA">Loading...</Typography>
          ) : (
            <Typography variant="h4" color="#00D4AA" sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }}>
              ${Number(dashboardData.total_available_balance || 0).toFixed(2)}
            </Typography>
          )}

<Typography variant="body2" color="#00D4AA">
  Today's Profit: {loading ? (
    <span>Loading...</span>
  ) : (
    <>
      +{Number(dashboardData.todays_earnings || 0).toFixed(6)} (
      {dashboardData.total_available_balance
        ? `${((Number(dashboardData.todays_earnings || 0) / Number(dashboardData.total_available_balance - dashboardData.todays_earnings)) * 100).toFixed(2)}%`
        : '0.00%'
      })
    </>
  )}
</Typography>

        </Card>

        <Typography variant="h5" color="#fff" sx={{ margin: '20px 0' }}>Your Portfolio</Typography>

        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', width: '90%' }}>
          {[
            { title: 'Today Earnings', value: dashboardData.todays_earnings },
            { title: 'Total Deposit', value: dashboardData.total_deposit_balance },
            { title: 'Total Withdraw', value: dashboardData.total_withdraw_balance },
            { title: 'Total Referred Gift', value: dashboardData.total_referred_gift_balance },
          ].map((item) => (
            <InfoCard key={item.title}>
              <Typography variant="h6" color="#fff">{item.title}</Typography>
              {loading ? ( // Loading state for each portfolio item
                <Typography variant="h6" color="#00D4AA">Loading...</Typography>
              ) : (
                <Typography variant="h4" color="#00D4AA" sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }}>
                  ${Number(item.value || 0).toFixed(2)}
                </Typography>
              )}
            </InfoCard>
          ))}
        </Box>

        <StyledBottomNavigation value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)}>
          <BottomNavigationAction
            label="Home"
            icon={<AddHomeIcon />}
            component={Link}
            to="/dashboard"
          />
          <BottomNavigationAction
            label="Wallet"
            icon={<AccountBalanceWalletIcon />}
            component={Link}
            to="/wallet"
          />
          <BottomNavigationAction
            label="Referral"
            icon={<Diversity3Icon />}
            component={Link}
            to="/refer"
          />
          <BottomNavigationAction
            label="Account"
            icon={<AccountCircleIcon />}
            component={Link}
            to="/account"
          />
        </StyledBottomNavigation>
      </>
    )}
  </DashboardContainer>
);

};

export default Dashboard;
