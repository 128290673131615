import React, { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import {
  Container,
  Box,
  Typography,
  BottomNavigationAction,
  BottomNavigation,
  styled,
  CircularProgress,
  Tooltip,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer
} from '@mui/material';
import AddHomeIcon from '@mui/icons-material/AddHome';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { QRCodeCanvas } from 'qrcode.react';
import { Link } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const DepositContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  background-color: #121212;
  padding-bottom: 56px;
`;

const Card = styled(Box)`
  padding: 20px;
  border-radius: 15px;
  width: 90%;
  margin: 20px 0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AddressBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const CopyButton = styled(Button)`
  margin-top: 10px;
  background-color: #00D4AA;
  color: #fff;
  &:hover {
    background-color: #00b998;
  }
`;

const StyledBottomNavigation = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #1e1e1e;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.5);
  padding: 10px 0;

  .MuiBottomNavigationAction-root {
    color: rgba(255, 255, 255, 0.7);
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: translateY(-5px);
    }

    &.Mui-selected {
      color: #00D4AA;
      transform: translateY(-5px);
    }
  }

  .MuiSvgIcon-root {
    font-size: 2rem;
  }

  .MuiBottomNavigationAction-label {
    font-size: 0.9rem;
    font-weight: 500;
  }
`;

const DepositPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [address, setAddress] = useState<string>('');
  const [copied, setCopied] = useState(false);
  const [deposits, setDeposits] = useState<any[]>([]); // State to store deposits

  const fetchDepositData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await axios.get('https://client.ecosystemdev.com/deposits/create', {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      setAddress(response.data.address || '');
    } catch (err) {
      const axiosError = err as AxiosError;
      const message = axiosError.response?.data as { message?: string };
      setError(message.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const fetchDepositHistory = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await axios.get('https://client.ecosystemdev.com/deposits', {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      setDeposits(response.data.deposits || []); // Fixed setting deposits correctly
    } catch (err) {
      const axiosError = err as AxiosError;
      const message = axiosError.response?.data as { message?: string };
      setError(message.message || 'Failed to load deposit history');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDepositData();
    fetchDepositHistory();
  }, []);

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(address).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <DepositContainer>
      <Typography variant="h4" color="#fff" sx={{ margin: '20px 0' }}>Deposit</Typography>

      <Card>
        {loading ? (
          <CircularProgress color="inherit" />
        ) : error ? (
          <Typography variant="body1" color="red">{error}</Typography>
        ) : (
          <>
 <Box sx={{ padding: '20px', backgroundColor: '#fff', borderRadius: '10px' }}>
    <QRCodeCanvas value={address || '0'} size={200} />
  </Box>            <Typography variant="h5" color="#fff" sx={{ marginTop: '20px', textAlign: 'center' }}>
              Scan the QR Code to Deposit
            </Typography>
            <Typography variant="body1" color="#00D4AA" sx={{ marginTop: '8px', textAlign: 'center' }}>
              Use the QR code above to deposit funds to your account.
            </Typography>

            <AddressBox>
              <Typography variant="body2" color="#fff" sx={{ wordWrap: 'break-word', maxWidth: '100%', textAlign: 'center' }}>
                {address}
              </Typography>
              <Tooltip title={copied ? "Copied!" : "Copy Address"}>
                <CopyButton
                  variant="contained"
                  startIcon={<ContentCopyIcon />}
                  onClick={handleCopyAddress}
                >
                  {copied ? "Copied!" : "Copy Address"}
                </CopyButton>
              </Tooltip>
            </AddressBox>
          </>
        )}
      </Card>

      <Card>
        {loading ? (
          <CircularProgress color="inherit" />
        ) : error ? (
          <Typography variant="body1" color="red">{error}</Typography>
        ) : (
          <>
            <Typography variant="h5" color="#fff" sx={{ marginBottom: '10px', textAlign: 'center' }}>
              Deposit History
            </Typography>
            {deposits.length === 0 ? (
              <Typography variant="body1" color="#fff">No deposit history available.</Typography>
            ) : (
              <TableContainer component={Paper} sx={{ width: '100%', marginTop: '10px', backgroundColor: '#1e1e1e' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: '#fff' }}>Date</TableCell>
                      <TableCell sx={{ color: '#fff' }}>Amount</TableCell>
                      <TableCell sx={{ color: '#fff' }}>Sender Address</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deposits.map((deposit) => (
                      <TableRow key={deposit.id}>
                        <TableCell sx={{ color: '#fff' }}>{new Date(deposit.created_at).toLocaleDateString()}</TableCell>
                        <TableCell sx={{ color: '#fff' }}>
  {parseFloat(deposit.forwarded_coin).toFixed(2)}
</TableCell>
                        <TableCell sx={{ color: '#fff' }}>{deposit.address_in}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </Card>

      <StyledBottomNavigation>
        <BottomNavigationAction label="Home" icon={<AddHomeIcon />} component={Link} to="/dashboard" />
        <BottomNavigationAction label="Wallet" icon={<AccountBalanceWalletIcon />} component={Link} to="/wallet" />
        <BottomNavigationAction label="Referrals" icon={<Diversity3Icon />} component={Link} to="/refer" />
        <BottomNavigationAction label="Profile" icon={<AccountCircleIcon />} component={Link} to="/account" />
      </StyledBottomNavigation>
    </DepositContainer>
  );
};

export default DepositPage;
