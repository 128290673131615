import React, { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import {
  Container,
  Box,
  Typography,
  BottomNavigationAction,
  BottomNavigation,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material';
import AddHomeIcon from '@mui/icons-material/AddHome';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link } from 'react-router-dom';
import DepositImage from './image/deposit.png';
import WithdrawImage from './image/withdraw.png';

// Styled components
const WalletContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  background-color: #121212;
  padding-bottom: 56px;
`;

const Card = styled(Box)`
  padding: 20px;
  border-radius: 15px;
  width: 90%;
  margin: 20px 0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonGroup = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ActionButton = styled(Button)`
  flex: 1;
  padding: 20px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
  &:hover {
    background-color: #2a2a2a;
  }
`;

const StyledBottomNavigation = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #1e1e1e;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.5);
  padding: 10px 0;

  .MuiBottomNavigationAction-root {
    color: rgba(255, 255, 255, 0.7);
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: translateY(-5px);
    }

    &.Mui-selected {
      color: #00d4aa;
      transform: translateY(-5px);
    }
  }

  .MuiSvgIcon-root {
    font-size: 2rem;
  }

  .Mui-selected .MuiSvgIcon-root {
    font-size: 2.2rem;
  }

  .MuiBottomNavigationAction-label {
    font-size: 0.9rem;
    font-weight: 500;
  }
`;

interface DashboardData {
  total_available_balance: string;
  total_deposit_balance: string;
  total_withdraw_balance: string;
  total_referred_gift_balance: string;
  todays_earnings: number;
}

// Example definition of Transaction type
interface Transaction {
  wallet_address: any;
  amount: any;
  status: any;
  date: any;
 

  id: number;               // or string depending on your backend
  created_at: string;       // Assuming it's a date string
  forwarded_coin: string;   // Assuming it's a string representing a numeric value
  address_in: string;       // or any other type as per your API
  // Add other properties as needed
}



const Wallet: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [deposits, setDeposits] = useState<Transaction[]>([]);
  const [withdraws, setWithdraws] = useState<Transaction[]>([]);
  const [error, setError] = useState<string | null>(null); // For error handling
  const [activeTab, setActiveTab] = useState<number>(1);
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);
  useEffect(() => {
    const fetchDashboardData = async () => {
      setLoading(true);
      try {
        const tokenData = localStorage.getItem('token');
        if (!tokenData) {
          throw new Error('Token not found in local storage');
        }

        const tokenString = JSON.parse(tokenData).token;

        const response = await axios.get('https://client.ecosystemdev.com/api/udashboard', {
          headers: {
            Authorization: `Bearer ${tokenString}`,
          },
        });

        setDashboardData(response.data.data);
      } catch (error) {
        setError('Failed to load dashboard data.');
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  const fetchDepositHistory = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await axios.get('https://client.ecosystemdev.com/deposits', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setDeposits(response.data.deposits || []);
    } catch (err) {
      const axiosError = err as AxiosError;
      const message = axiosError.response?.data as { message?: string };
      setError(message.message || 'Failed to load deposit history');
    } finally {
      setLoading(false);
    }
  };

  const fetchWithdrawHistory = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await axios.get('https://client.ecosystemdev.com/bep/withdraw', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setWithdraws(response.data.withdraws || []);
    } catch (err) {
      const axiosError = err as AxiosError;
      const message = axiosError.response?.data as { message?: string };
      setError(message.message || 'Failed to load withdraw history');
    } finally {
      setLoading(false);
    }
  };



  const fetchDashboardData = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
  
      const response = await axios.get('https://client.ecosystemdev.com/bep/dashboard', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
     
      
      // Assuming the real data is nested inside response.data.data
      setDashboardData(response.data.data);
      setLoading(false);
    } catch (error: any) {
      console.error('Error fetching dashboard data:', error.response?.status, error.response?.data);
      setError('Failed to load dashboard data.');
      setLoading(false);
    }
  };
  



  useEffect(() => {
    fetchDepositHistory();
    fetchWithdrawHistory();
    fetchDashboardData();
  }, []);










  
  return (
    <WalletContainer>
      <Typography variant="h4" color="#fff" sx={{ margin: '20px 0' }}>Wallet</Typography>

      <Card>
      <Typography variant="h5" color="#fff">Total Balance (USD)</Typography>
          {loading ? ( // Loading state for Total Balance
            <Typography variant="h6" color="#00D4AA">Loading...</Typography>
          ) : (
           // Later in your component
<Typography variant="h4" color="#00D4AA" sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }}>
  ${dashboardData ? Number(dashboardData.total_available_balance || 0).toFixed(2) : '0.00'}
</Typography>


          )}
      </Card>

      <ButtonGroup>
        <ActionButton>
          <Link
            to="/deposit"
            style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <img src={DepositImage} alt="Deposit" style={{ width: '60px', height: '60px' }} />
            <Typography variant="body1" color="#fff" sx={{ marginTop: '8px' }}>Deposit</Typography>
          </Link>
        </ActionButton>

        <ActionButton>
          <Link
            to="/withdraw"
            style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <img src={WithdrawImage} alt="Withdraw" style={{ width: '60px', height: '60px' }} />
            <Typography variant="body1" color="#fff" sx={{ marginTop: '8px' }}>Withdraw</Typography>
          </Link>
        </ActionButton>
      </ButtonGroup>

      {/* Deposit History Table */}
      <Typography variant="h5" color="#fff" sx={{ marginTop: '20px', width: '90%' }}>Deposit History</Typography>
      <TableContainer component={Paper} sx={{ width: '100%', backgroundColor: '#1e1e1e' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: '#fff' }}>Date</TableCell>
              <TableCell sx={{ color: '#fff' }}>Amount (USD)</TableCell>
              <TableCell sx={{ color: '#fff' }}>Sender Address</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
                    {deposits.map((deposit) => (
                      <TableRow key={deposit.id}>
                        <TableCell sx={{ color: '#fff' }}>{new Date(deposit.created_at).toLocaleDateString()}</TableCell>
                        <TableCell sx={{ color: '#fff' }}>
  {parseFloat(deposit.forwarded_coin).toFixed(2)}
</TableCell>
                        <TableCell sx={{ color: '#fff' }}>{deposit.address_in}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>

        </Table>
      </TableContainer>

      {/* Withdraw History Table */}
      <Typography variant="h5" color="#fff" sx={{ marginTop: '20px', width: '90%' }}>Withdraw History</Typography>
      <TableContainer
              component={Paper}
              sx={{ width: '100%', marginTop: '10px', backgroundColor: '#1e1e1e' }}
          >
              <Table>
                  <TableHead>
                      <TableRow>
                          <TableCell sx={{ color: '#fff' }}>Date</TableCell>
                          <TableCell sx={{ color: '#fff' }}>Status</TableCell>
                          <TableCell sx={{ color: '#fff' }}>Amount</TableCell>
                          <TableCell sx={{ color: '#fff' }}>Sender Address</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {withdraws.map((withdraw) => (
                          <TableRow key={withdraw.id}>
                              <TableCell sx={{ color: '#fff' }}>
                                  {new Date(withdraw.created_at).toLocaleDateString()}
                              </TableCell>
                              <TableCell sx={{ color: '#fff' }}>
                                  {withdraw.status} {/* Display the status directly */}
                              </TableCell>
                              <TableCell sx={{ color: '#fff' }}>
                                  {parseFloat(withdraw.amount).toFixed(2)} {/* Format amount */}
                              </TableCell>
                              <TableCell sx={{ color: '#fff' }}>{withdraw.wallet_address}</TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </Table>
          </TableContainer>
<><br /></>
          <StyledBottomNavigation value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)}>
          <BottomNavigationAction label="Home" icon={<AddHomeIcon />} component={Link} to="/dashboard" />
        <BottomNavigationAction label="Wallet" icon={<AccountBalanceWalletIcon />}/>
        <BottomNavigationAction label="Referrals" icon={<Diversity3Icon />} component={Link} to="/refer" />
        <BottomNavigationAction label="Profile" icon={<AccountCircleIcon />} component={Link} to="/account" />
      </StyledBottomNavigation>
    </WalletContainer>
  );
};

export default Wallet;
